import React from "react";

function LinkedIN({ className }) {
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <path
        fill="#4f4f4f"
          d="M448.9,31H63c-17.7,0-32,14.6-32,32.4v385.1c0,17.9,14.4,32.4,32,32.4h385.8c17.7,0,32.1-14.6,32.1-32.4V63.4
		C481,45.6,466.5,31,448.9,31z M167,416.7h-66.7V202h66.8v214.8H167z M133.7,172.6c-21.4,0-38.7-17.4-38.7-38.7s17.3-38.7,38.7-38.7
		c21.3,0,38.7,17.4,38.7,38.7C172.3,155.4,155.1,172.6,133.7,172.6z M417,416.7h-66.7V312.2c0-24.9-0.5-57-34.7-57
		c-34.8,0-40.1,27.1-40.1,55.1v106.3h-66.7V202h64v29.3h0.9c8.9-16.9,30.7-34.7,63.2-34.7c67.5,0,80.1,44.5,80.1,102.4V416.7z"
        />
      </g>
    </svg>
  );
}

export default LinkedIN;
